import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GameStoreContext } from "../../../store/GameStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CodeModal = ({ show, handleClose, handleChangeRoomCode }) => {
	const gameStore = useContext(GameStoreContext);
	const [reveal, setReveal] = useState(false);

	let baseUrl = window.location.origin;
	baseUrl = baseUrl.replace(/(^\w+:|^)\/\//, "");

	React.useEffect(() => {
		setTimeout(() => {
			setReveal(true);
		}, 12000);
	}, []);

	const viewQR = () => {
		handleClose();
	};
	return (
		<Modal
			className="admin-view qr-modal"
			show={show}
			onHide={handleClose}
			backdrop="static"
			keyboard={false}
			size="md"
		>
			<Modal.Header closeButton>
				<div>
					Provide this link or QR code to your players so they can submit their
					answers via their device. Or they can go to {window.APP_SITE_LABEL}{" "}
					and enter {gameStore.roomCode} as the game code.
				</div>
			</Modal.Header>
			<Modal.Body className="d-flex flex-column align-items-center">
				{/* <div className="qr-code">
					<img
						src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${window.location.href}`}
						alt="QR Code"
					/>
				</div> */}

				<p className="header-title fadeInUp">
					{baseUrl}/{gameStore.roomCode}
				</p>

				<Link to="/host/qr-code">
					<Button variant="light" onClick={viewQR}>
						<FontAwesomeIcon icon="qrcode" className="mr-1" /> QR Code
					</Button>
				</Link>
				<br />
				<Button variant="outline-primary" onClick={handleChangeRoomCode}>
					<FontAwesomeIcon icon="pencil-alt" className="mr-1" /> Change room
					code
				</Button>
			</Modal.Body>
			<Modal.Footer>
				<div className="p-2">
					<Button type="submit" variant="primary" onClick={handleClose}>
						OK
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default CodeModal;
