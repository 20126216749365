import axios from "axios";
import { checkAuth } from "../util/localStorage";
import { baseURL } from "../util/constants";

const api = axios.create({ baseURL });

const cache = {
	answers: {
		data: null,
		timestamp: 0,
		pendingPromise: null,
	},
	questions: {
		data: null,
		timestamp: 0,
		pendingPromise: null,
	},
	question: {}, // Object to store caches for different question numbers
	game: {
		data: null,
		timestamp: 0,
		pendingPromise: null,
	},
	currentRound: {
		data: null,
		timestamp: 0,
		pendingPromise: null,
	},
};

const timeoutPromise = (ms) =>
	new Promise((_, reject) =>
		setTimeout(() => reject(new Error("Request timed out")), ms)
	);

export const playerAPI = {
	getRoom(room) {
		return api.get("player/room/" + room).then((res) => {
			return res.data;
		});
	},
	playerLogin(room, name) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.post(
				"player/login",
				{
					room: room,
					name: name,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return { data: res.data, status: res.status };
			});
	},
	getUserName(room) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.get(`user/name/${room}`, {
				headers: {
					Authorization: auth,
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	setNewUserName(room, name) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.post(
				`user/name/${room}`,
				{
					name: name,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	getGame() {
		const token = checkAuth();
		const auth = "Bearer " + token;
		const now = Date.now();

		// Check if we have valid cached data (within 1 second)
		if (
			cache.game.data !== null &&
			now - cache.game.timestamp < 1000 &&
			!cache.game.pendingPromise
		) {
			return Promise.resolve(cache.game.data);
		}

		// If there's an ongoing request, return its promise
		if (cache.game.pendingPromise) {
			return cache.game.pendingPromise;
		}

		// Start new request and cache the promise
		cache.game.pendingPromise = api
			.get("player/game", {
				headers: {
					Authorization: auth,
				},
			})
			.then((res) => {
				cache.game.data = res.data;
				cache.game.timestamp = Date.now();
				cache.game.pendingPromise = null; // Clear the pending promise
				return res.data;
			})
			.catch((error) => {
				cache.game.pendingPromise = null; // Clear on error too
				throw error;
			});

		return cache.game.pendingPromise;
	},
	getQuestion(questionNum) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		const now = Date.now();

		// Initialize cache for this questionNum if it doesn't exist
		if (!cache.question[questionNum]) {
			cache.question[questionNum] = {
				data: null,
				timestamp: 0,
				pendingPromise: null,
			};
		}

		const questionCache = cache.question[questionNum];

		// Check if we have valid cached data (within 1 second)
		if (
			questionCache.data !== null &&
			now - questionCache.timestamp < 1000 &&
			!questionCache.pendingPromise
		) {
			return Promise.resolve(questionCache.data);
		}

		// If there's an ongoing request for this question, return its promise
		if (questionCache.pendingPromise) {
			return questionCache.pendingPromise;
		}

		// Start new request and cache the promise
		questionCache.pendingPromise = Promise.race([
			api.get("player/answer/" + questionNum, {
				headers: { Authorization: auth },
			}),
			timeoutPromise(5000), // 5-second timeout
		])
			.then((res) => {
				questionCache.data = res.data;
				questionCache.timestamp = Date.now();
				questionCache.pendingPromise = null; // Clear the pending promise
				return res.data;
			})
			.catch((error) => {
				questionCache.pendingPromise = null; // Clear on error too
				throw error;
			});

		return questionCache.pendingPromise;
	},
	getAnswers() {
		const token = checkAuth();
		const auth = "Bearer " + token;
		const now = Date.now();

		// Check if we have valid cached data (within 1 second)
		if (
			cache.answers.data !== null &&
			now - cache.answers.timestamp < 1000 &&
			!cache.answers.pendingPromise
		) {
			return Promise.resolve(cache.answers.data);
		}

		// If there's an ongoing request, return its promise
		if (cache.answers.pendingPromise) {
			return cache.answers.pendingPromise;
		}

		// Start new request and cache the promise
		cache.answers.pendingPromise = api
			.get("player/answers", {
				headers: {
					Authorization: auth,
				},
			})
			.then((res) => {
				cache.answers.data = res.data;
				cache.answers.timestamp = Date.now();
				cache.answers.pendingPromise = null; // Clear the pending promise
				return res.data;
			})
			.catch((error) => {
				cache.answers.pendingPromise = null; // Clear on error too
				throw error;
			});

		return cache.answers.pendingPromise;
	},
	submitAnswer(answer, questionNum, bet) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api.post(
			"player/answer/" + questionNum,
			{ answer, bet },
			{
				headers: {
					Authorization: auth,
				},
			}
		);
	},
	getQuestions() {
		const token = checkAuth();
		const auth = "Bearer " + token;
		const now = Date.now();

		// Check if we have valid cached data (within 1 second)
		if (
			cache.questions.data !== null &&
			now - cache.questions.timestamp < 1000 &&
			!cache.questions.pendingPromise
		) {
			return Promise.resolve(cache.questions.data);
		}

		// If there's an ongoing request, return its promise
		if (cache.questions.pendingPromise) {
			return cache.questions.pendingPromise;
		}

		// Start new request and cache the promise
		cache.questions.pendingPromise = api
			.get("player/questions", {
				headers: {
					Authorization: auth,
				},
			})
			.then((res) => {
				cache.questions.data = res.data;
				cache.questions.timestamp = Date.now();
				cache.questions.pendingPromise = null; // Clear the pending promise
				return res.data;
			})
			.catch((error) => {
				cache.questions.pendingPromise = null; // Clear on error too
				throw error;
			});

		return cache.questions.pendingPromise;
	},
	getImage(questionNum) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.get("player/image/" + questionNum, {
				headers: {
					Authorization: auth,
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	getCurrentRound() {
		const token = checkAuth();
		const auth = "Bearer " + token;
		const now = Date.now();

		// Check if we have valid cached data (within 1 second)
		if (
			cache.currentRound?.data !== null &&
			now - cache.currentRound?.timestamp < 1000 &&
			!cache.currentRound?.pendingPromise
		) {
			return Promise.resolve(cache.currentRound.data);
		}

		// Initialize cache if it doesn't exist
		if (!cache.currentRound) {
			cache.currentRound = {
				data: null,
				timestamp: 0,
				pendingPromise: null,
			};
		}

		// If there's an ongoing request, return its promise
		if (cache.currentRound.pendingPromise) {
			return cache.currentRound.pendingPromise;
		}

		// Start new request and cache the promise
		cache.currentRound.pendingPromise = api
			.get("player/current-round", {
				headers: {
					Authorization: auth,
				},
			})
			.then((res) => {
				cache.currentRound.data = res.data;
				cache.currentRound.timestamp = Date.now();
				cache.currentRound.pendingPromise = null; // Clear the pending promise
				return res.data;
			})
			.catch((error) => {
				cache.currentRound.pendingPromise = null; // Clear on error too
				throw error;
			});

		return cache.currentRound.pendingPromise;
	},
};
