import React from "react";
//import FakeAd from "./FakeAd";
import "./style.scss";
export default class AdSense extends React.Component {
	componentDidMount() {
		console.log("Rendering ad");
		(window.adsbygoogle = window.adsbygoogle || []).push({});
	}

	render() {
		const isLocal = process.env.REACT_APP_ENV == "local"; // ? "true" : "false";

		return (
			// <div
			// 	style={{

			// 		position: "fixed",
			// 		bottom: 0,
			// 		left: 0,
			// 		right: 0,
			// 		display: "flex",
			// 		flexDirection: "column-reverse",
			// 		padding: 0,
			// 		alignContent: "",
			// 	}}
			// >
			<ins
				className="adsbygoogle"
				style={{
					display: "inlineblock",
					width: 440 + "px",
					height: 130 + "px",
				}}
				data-ad-client="ca-pub-3763096470697498"
				data-ad-slot="6063385472"
			></ins>
			// </div>
		);
	}
}
