import React, { useEffect, useState, useCallback } from "react";
import { io } from "socket.io-client";

// Change this to your actual backend WebSocket URL
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const userFromLS = JSON.parse(localStorage.getItem("user"));

const SocketTest = () => {
    const [mode, setMode] = useState(null);
    const [connected, setConnected] = useState(false);

    // Encapsulate the Socket.IO connection logic in a useCallback hook
    const connectToSockets = useCallback((gameId, userId) => {
        console.log("Attempting to connect to socket...");

        // Initialize socket connection
        const socket = io(SOCKET_URL, {
            transports: ["websocket"], // Forces WebSocket transport
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
        });

        // Handle connection
        socket.on("connect", () => {
            console.log("-- Connected to Socket --");
            console.log("User ID:", userId);
            setConnected(true);

            // Join the room and emit playerWS event
            socket.emit("room", gameId);
            socket.emit("playerWS", userId);
        });

        // Listen for "mode" event
        socket.on("mode", (data) => {
            console.log("Received mode event:", data);
            setMode(data.mode); // Update the mode state
        });

        // Handle errors
        socket.on("connect_error", (err) => {
            console.error("Connection error:", err);
            setConnected(false);
        });

        // Cleanup on unmount
        return () => {
            console.log("Disconnecting socket...");
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        const gameId = "12345"; // Replace with your actual game ID
        const userId = userFromLS?.ID; // Get the user ID from localStorage

        if (userId) {
            // Call the connectToSockets function
            const cleanup = connectToSockets(gameId, userId);

            // Cleanup function for useEffect
            return cleanup;
        } else {
            console.error("User ID not found in localStorage");
        }
    }, [connectToSockets]);

    return (
        <div style={{ padding: "20px", textAlign: "center", fontFamily: "Arial, sans-serif" }}>
            <h2>Socket.IO Test</h2>
            <p>
                <strong>Connection Status:</strong> {connected ? "✅ Connected" : "❌ Disconnected"}
            </p>
            <p>
                <strong>Latest Mode Event:</strong> {mode || "No mode event received yet"}
            </p>
            <div style={{ paddingTop: "40px", textAlign: "center" }}>
            <button 
            className="btn btn-outline-dark btn-sm"
            onClick={() => {throw new Error("This is your first error!");}}>Trigger Error (Sentry)</button>
            </div>
        </div>


    );
};

export default SocketTest;