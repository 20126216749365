import React, {
	useState,
	useEffect,
	useContext,
	Suspense,
	lazy,
	useCallback,
} from "react";
import Header from "./Header/Header";

import "./style.scss";
import { showToast } from "../../util/toast";

import "../../components/FontAwesomeIcons";
//import openSocket from "socket.io-client";
import { io } from "socket.io-client";

import { observer } from "mobx-react";
import { GameStoreContext } from "../../store/GameStore";
import { hostAPI } from "../../api/host-api";

import CodeModal from "./Modals/CodeModal";
import LearnMoreModal from "./Modals/LearnMoreModal";
import ChangeRoomCode from "./Modals/ChangeRoomCode";

import { cleanLocalStorage } from "../../util/localStorage";
import PrivateRoute from "../../Router/Routes/PrivatRoute";
import { RoleStoreContext } from "../../store/RoleStrore";
import { Redirect, Switch, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import ReactLoading from "react-loading";
import { socketUrl } from "../../util/constants";
import ReactGA from "react-ga4";
//import AdSense from "../../components/AdSense/AdSense";

const GameDashboard = lazy(() => import("./GameDashboard/GameDashboard"));
const Settings = lazy(() => import("./Settings/Settings"));
const PremiumUpgrade = lazy(() => import("./PremiumUpgrade/PremiumUpgrade"));
const RawData = lazy(() => import("./RawData/RawData"));
const MyQRCode = lazy(() => import("./MyQRCode/MyQRCode"));
const Guide = lazy(() => import("./Guide/Guide"));
const Users = lazy(() => import("./Users/Users"));
const VenueConnect = lazy(() => import("./Settings/VenueConnect"));
const RoundSnapshot = lazy(() => import("./RoundSnapshot/RoundSnapshot"));
const ImagesController = lazy(() =>
	import("./ImagesController/ImagesController")
);
const PreEnteredQuestions = lazy(() =>
	import("./PreEnteredQuestions/PreEnteredQuestions")
);
const AuthContact = lazy(() => import("./AuthContact/AuthContact"));

let socket;
// const createNewConnection = () => {
// 	socket = openSocket(socketUrl, {
// 		reconnection: false,
// 	});
// };
const createNewConnection = () => {
	socket = io(socketUrl, {
		transports: ["websocket"], // ✅ Use WebSocket transport
		withCredentials: false, // ✅ Allow credentials if needed
		reconnection: true, // ✅ Auto-reconnect
		reconnectionAttempts: 5, // ✅ Retry up to 5 times
		reconnectionDelay: 2000, // ✅ Delay between retries
	});
};
let savedId;

const Host = observer((props) => {
	const gameStore = useContext(GameStoreContext);
	const roleStore = useContext(RoleStoreContext);
	const location = useLocation();

	const [roomId, setRoomId] = useState("");
	const [showCodeModal, setCodeModal] = useState(false);
	const [showLearnMoreModal, setLearnMoreModal] = useState(false);
	const [roomCode, setRoomCode] = useState(String);
	const [showChangeRoomCode, setShowChangeRoomCode] = useState(false);
	const [afterReconnect, setAfterReconnect] = useState(false);
	//const [adsenseKey, setAdsenseKey] = useState("");

	ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

	// useEffect(() => {
	// 	setAdsenseKey(location.pathname);
	// }, [location]);

	const handleLogOut = () => {
		if (gameStore.role === "auth" || gameStore.role === "pro") {
			localStorage.removeItem("user");
			window.location.pathname = "/sign-in";
		} else if (gameStore.role === "guest") {
			hostAPI
				.deleteHost()
				.then((res) => {
					gameStore.clearAll();
				})
				.then(() => {
					localStorage.removeItem("user");
				})
				.then(() => {
					window.location.reload();
				})
				.catch((err) => {
					if (err.response.status === 404) {
						cleanLocalStorage();
					} else {
						console.log(err);
					}
				});
		}
	};

	const handleCodeModal = () => {
		setCodeModal(true);
	};

	const handleChangeRoomCode = () => {
		setCodeModal(false);
		setShowChangeRoomCode(true);
	};

	const handleLearnMoreModal = () => {
		setLearnMoreModal(true);
	};

	const handleClose = () => {
		setCodeModal(false);
		setLearnMoreModal(false);
		setShowChangeRoomCode(false);
	};

	const getGame = useCallback(() => {
		hostAPI
			.getGame()
			.then((res) => {
				gameStore.email = res.email;
				gameStore.id = res.adminId;
				gameStore.createdAt = res.createdAt;

				if (res.game) {
					const {
						roomCode,
						role,
						helperTips,
						halfPoints,
						showAnswers,
						showCorrectAnswers,
						displayCorrectAnswer,
						requirePlayerEmail,
						lockQuestion,
						lockedQuestion,
						lockQuestionMode,
						switchRounds,
						imageMode,
						questionsPerRound,
						apiSecret,
						gameMode,
						wagerMode,
						apiIntegration,
						scoringInterval,
						playersLimit,
						scoreTableKey,
						showQuestions,
						adjustByResponse,
						twitchIntegration,
						adjustByQuestion,
						maxPointValue,
						zoomUrl,
						twitchUrl,
						customTitle,
						customUrl,
						customUrlTitle,
						roundCompleteMessage,
						roundMode,
						showScores,
						pauseModeContent,
						gamePauseMessage,
						sponsoredByAdmin,
					} = res.game;
					gameStore.roomCode = roomCode;
					setRoomCode(gameStore.roomCode);

					gameStore.role = role;

					gameStore.helperTips = helperTips;

					gameStore.halfPoints = halfPoints;

					gameStore.requirePlayerEmail = requirePlayerEmail;

					gameStore.lockQuestion = lockQuestion;

					gameStore.lockedQuestion = lockedQuestion;

					gameStore.lockQuestionMode = lockQuestionMode;

					gameStore.showAnswers = showAnswers;

					gameStore.showCorrectAnswers = showCorrectAnswers;

					gameStore.showScores = showScores;

					gameStore.imageMode = imageMode;

					gameStore.questionsPerRound = questionsPerRound;

					gameStore.apiSecret = apiSecret;

					gameStore.gameMode = gameMode;

					gameStore.wagerMode = wagerMode;

					gameStore.apiIntegration = apiIntegration;

					gameStore.scoringInterval = scoringInterval;
					res.game.players.forEach((player) => gameStore.addPlayer(player));
					let totalAnswers = 0;
					let totalMarks = 0;
					gameStore.setRound(res.game.round);
					res.game.round.forEach((question) => {
						question.answers.forEach((answer) => {
							totalAnswers++;
							if (answer.halfPoint) {
								totalMarks = totalMarks + 0.5;
							}
							if (answer.point) {
								totalMarks = totalMarks + 1;
							}
						});
					});
					gameStore.totalAnswers = totalAnswers;

					gameStore.markedAnswers = totalMarks;

					gameStore.sponsoredByAdmin = sponsoredByAdmin;

					gameStore.roundMode = roundMode;

					if (res.game.role === "pro") {
						gameStore.playersLimit = playersLimit;

						gameStore.scoreTableKey = scoreTableKey;

						gameStore.showQuestions = showQuestions;

						gameStore.displayCorrectAnswer = displayCorrectAnswer;

						gameStore.adjustByResponse = adjustByResponse;

						gameStore.twitchIntegration = twitchIntegration;

						gameStore.switchRounds = switchRounds;

						gameStore.adjustByQuestion = adjustByQuestion;

						gameStore.maxPointValue = maxPointValue;

						gameStore.pauseModeContent = pauseModeContent;

						gameStore.gamePauseMessage = gamePauseMessage;

						gameStore.zoomUrl = zoomUrl;

						gameStore.twitchUrl = twitchUrl;

						gameStore.customTitle = customTitle;

						gameStore.customUrl = customUrl;

						gameStore.customUrlTitle = customUrlTitle;

						gameStore.roundCompleteMessage = roundCompleteMessage;
					} else {
						gameStore.playersLimit = 5;

						gameStore.showQuestions = false;

						gameStore.adjustByResponse = false;

						gameStore.twitchIntegration = false;

						gameStore.adjustByQuestion = false;

						gameStore.switchRounds = false;

						gameStore.displayCorrectAnswer = false;

						gameStore.maxPointValue = 0;

						gameStore.pauseModeContent = "scoreTable";

						gameStore.gamePauseMessage = "";
					}

					setRoomId(res.game._id);
					savedId = res.game._id;
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 430) {
					localStorage.removeItem("user");
					window.location.pathname = "/";
				} else {
					console.log(err);
				}
			});
	}, [gameStore]);

	useEffect(() => {
		getGame();
	}, [getGame]);

	const connectToSockets = useCallback(
		(roomId) => {
			socket.emit("room", roomId);
			socket.on("player", (data) => {
				if (data && data.action === "join" && data.player) {
					if (
						!gameStore.players.find(
							(p) => p._id.toString() === data.player._id.toString()
						)
					) {
						gameStore.addPlayer(data.player);
					}
				}
			});
			socket.on("answer", (data) => {
				if (data && data.action === "submitted" && data.question) {
					gameStore.updateQuestion(data.question);
				}
			});
		},
		[gameStore]
	);

	useEffect(() => {
		createNewConnection();
	}, []);

	useEffect(() => {
		if (afterReconnect) {
			createNewConnection();
			setAfterReconnect(false);
		}
	}, [afterReconnect]);

	useEffect(() => {
		if (roomId.length) {
			connectToSockets(roomId);
		}
	}, [roomId, connectToSockets]);

	const tryReconnect = () => {
		setTimeout(() => {
			socket.io.open((err) => {
				if (err) {
					tryReconnect();
				} else {
					if (savedId.length) {
						setAfterReconnect(true);
						createNewConnection();
						connectToSockets(savedId);
						gameStore.clearAll();
						getGame();
					}
				}
			});
		}, 1000);
	};

	useEffect(() => {
		setRoomCode(gameStore.roomCode);
	}, [gameStore.roomCode]);

	if (socket) {
		socket.io.on("close", () => {
			tryReconnect();
		});
	}

	return (
		<div
			className={`admin-view ${
				!!gameStore.role && gameStore.role !== "pro" && "with-ad"
			}`}
		>
			<Helmet>
				<title>{window.APP_NAME} | Admin Portal</title>
			</Helmet>
			<Header logOut={handleLogOut} roomCode={roomCode}>
				<Suspense
					fallback={
						<div className="page-preloader">
							<ReactLoading
								type="spinningBubbles"
								color="#1b3b6f"
								height={"100px"}
								width={"100px"}
							/>
						</div>
					}
				>
					<Switch>
						<PrivateRoute
							exact
							path="/host/dashboard"
							user={roleStore}
							component={() => (
								<GameDashboard
									handleCodeModal={handleCodeModal}
									isFirst={props.isFirst}
									handleLearnMoreModal={handleLearnMoreModal}
								/>
							)}
						/>
						<PrivateRoute
							exact
							path="/host/settings/:tab?"
							user={roleStore}
							component={Settings}
						/>
						<PrivateRoute
							exact
							path="/host/guide"
							user={roleStore}
							component={Guide}
						/>
						<PrivateRoute
							exact
							path="/host/images"
							user={roleStore}
							component={ImagesController}
						/>
						<PrivateRoute
							exact
							path="/host/qr-code"
							user={roleStore}
							component={() => <MyQRCode roomCode={roomCode} />}
						/>
						<PrivateRoute
							exact
							path="/host/venue-connect"
							user={roleStore}
							component={() => <VenueConnect />}
						/>
						<PrivateRoute
							exact
							path="/host/raw-data"
							user={roleStore}
							component={RawData}
						/>
						<PrivateRoute
							exact
							path="/host/round-snapshot"
							user={roleStore}
							component={RoundSnapshot}
						/>
						<PrivateRoute
							exact
							path="/host/premium"
							user={roleStore}
							component={PremiumUpgrade}
						/>{" "}
						<PrivateRoute
							exact
							path="/host/users"
							user={roleStore}
							component={Users}
						/>
						<PrivateRoute
							exact
							path="/host/pre-entered"
							user={roleStore}
							component={PreEnteredQuestions}
						/>
						<PrivateRoute
							exact
							path="/host/contact/:type?"
							user={roleStore}
							component={AuthContact}
						/>
						<Redirect path="/host/" to="/host/dashboard" />
					</Switch>
				</Suspense>
			</Header>

			<CodeModal
				show={showCodeModal}
				handleClose={handleClose}
				handleChangeRoomCode={handleChangeRoomCode}
			/>
			<ChangeRoomCode
				show={showChangeRoomCode}
				handleClose={handleClose}
				roomCode={roomCode}
			/>
			<LearnMoreModal show={showLearnMoreModal} handleClose={handleClose} />

			{/* {!!gameStore.role &&
        gameStore.role !== "pro" &&
        location.pathname !== "/host/dashboard" && (
          <AdSense key={`adsenseKey_${adsenseKey}`} />
        )} */}
		</div>
	);
});

export default Host;
