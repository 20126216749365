import axios from "axios";
import { checkAuth } from "../util/localStorage";
import { baseURL } from "../util/constants";

const api = axios.create({ baseURL });

export const authHostAPI = {
	verifyToken(token) {
		const url = "auth/verify-token/" + token;
		return api.get(url).then((res) => {
			return res.data;
		});
	},
	signUp(email, password, source, locationData) {
		return api
			.post("auth/signup", {
				email: email,
				password: password,
				source,
				locationData: locationData,
			})
			.then((res) => {
				return res.data;
			});
	},
	signUpGoogle(credential) {
		return api
			.post("auth/google-signup", {
				token: credential,
			})
			.then((res) => {
				return res.data;
			});
	},

	signUpVenue(venueName, fullName, email, locationData) {
		return api
			.post("venue/signup", {
				venueName: venueName,
				fullName: fullName,
				email: email,
				locationData: locationData,
			})
			.then((res) => {
				return res.data;
			});
	},
	login(login, password, locationData = {}) {
		return api
			.post("auth/login", {
				login,
				password,
				locationData,
			})
			.then((res) => {
				return res.data;
			});
	},
	updatePass(pass, newPass, confirmPass) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.put(
				"auth/update-pass",
				{
					password: pass,
					newPassword: newPass,
					confirmPassword: confirmPass,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	resetPass(email) {
		return api
			.post("auth/reset-pass", {
				email: email,
			})
			.then((res) => {
				return res.data;
			});
	},
	newPass(resToken, newPass, confirmPass) {
		return api
			.post("auth/new-pass", {
				resToken: resToken,
				newPassword: newPass,
				confirmPassword: confirmPass,
			})
			.then((res) => {
				return res.data;
			});
	},
	addImage(orderNum, url) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.post(
				"auth/image",
				{
					orderNum: orderNum,
					url: url,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	uploadImage(file) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		const fd = new FormData();
		fd.append("image", file);

		return api
			.post("auth/upload-image", fd, {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: auth,
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	getImages() {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.get("auth/images", {
				headers: {
					Authorization: auth,
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	deleteImage(orderNum) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.delete("auth/image", {
				headers: {
					Authorization: auth,
				},
				data: {
					orderNum: orderNum,
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	deleteAllImages() {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.delete("auth/images", {
				headers: {
					Authorization: auth,
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	changeImageMode(imageMode) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.put(
				"/auth/image-mode",
				{
					imageMode,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	updateSettings(
		halfPoints,
		helperTips,
		questionsPerRound,
		showAnswers,
		showScores,
		showCorrectAnswers,
		requirePlayerEmail,
		lockQuestion,
		lockQuestionMode,
		scoringInterval,
		playersLimit
	) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.put(
				"/auth/update-settings",
				{
					halfPoints,
					helperTips,
					questionsPerRound,
					showAnswers,
					showScores,
					showCorrectAnswers,
					requirePlayerEmail,
					lockQuestion,
					lockQuestionMode,
					scoringInterval,
					playersLimit,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	updateLockedQuestion(
		lockedQuestion,
	) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.put(
				"/auth/update-locked-question",
				{
					lockedQuestion
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	updateVenueConnect(data) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.put("/auth/update-venue-connect", data, {
				headers: {
					Authorization: auth,
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	changeRoomCode(newRoomCode) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.put(
				"/auth/change-room-code",
				{
					roomCode: newRoomCode,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	updateCustomSettings(
		customTitle,
		customUrl,
		customUrlTitle,
		roundCompleteMessage,
		zoomUrl,
		twitchUrl
	) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.put(
				"/auth/update-custom-settings",
				{
					customTitle,
					customUrl,
					customUrlTitle,
					roundCompleteMessage,
					zoomUrl,
					twitchUrl,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	updateProOptions(
		switchRounds,
		adjustByResponse,
		adjustByQuestion,
		maxPointValue,
		showQuestions,
		showAnswers,
		displayCorrectAnswer,
		pauseModeContent,
		gamePauseMessage
	) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.put(
				"/auth/update-pro-options",
				{
					switchRounds,
					adjustByResponse,
					adjustByQuestion,
					maxPointValue,
					showQuestions,
					showAnswers,
					displayCorrectAnswer,
					pauseModeContent,
					gamePauseMessage,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	adjustScore(answerId, increase) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.put(
				"/auth/update-score",
				{
					answerId,
					increase,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	updatePlan(subscriptionId) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.post(
				"auth/pro",
				{
					subscriptionId: subscriptionId,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	getSubscription() {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.get("/auth/subscription", {
				headers: {
					Authorization: auth,
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	getUsers() {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.get("/auth/users", {
				headers: {
					Authorization: auth,
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	cancelSubscription(reason) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.post(
				"/auth/cancel",
				{
					reason: reason,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	getRounds() {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.get("/auth/rounds", {
				headers: {
					Authorization: auth,
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	getRound(roundId) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.get("/auth/round/" + roundId, {
				headers: {
					Authorization: auth,
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	createRound(roundName, questionText, answerText, imgUrl) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.post(
				"/auth/create-round",
				{
					roundName,
					questionText,
					answerText,
					imgUrl,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	swapQuestion(source_id, _id) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.post(
				"/auth/swap-question/" + _id,
				{
					source_id: source_id,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},

	createQuestion(roundId, questionText, answerText, imgUrl) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.post(
				"/auth/add-question",
				{
					roundId,
					questionText,
					answerText,
					imgUrl,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	editRoundName(roundId, roundName) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.post(
				"/auth/edit-name",
				{ roundId, roundName },
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	editQuestion(roundId, questionText, answerText, orderNum, imgUrl) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.post(
				"/auth/edit-question",
				{ roundId, questionText, answerText, orderNum, imgUrl },
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	removeQuestion(roundId, orderNum) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.delete("/auth/question", {
				headers: {
					Authorization: auth,
				},
				data: {
					roundId,
					orderNum,
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	removeRound(roundId) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.delete("/auth/round", {
				headers: {
					Authorization: auth,
				},
				data: {
					roundId,
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	selectRound(roundMode, roundId, schemeId) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.post(
				"/auth/select-round",
				{ roundMode, roundId, schemeId },
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	getCurrentRound() {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.get("/auth/current-round", {
				headers: {
					Authorization: auth,
				},
			})
			.then((res) => {
				return res.data;
			});
	},
	automaticMode(amount, category, source) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.post(
				"/auth/automatic-round",
				{ amount, category, source },
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	getScoreTable(key) {
		return api.get("/auth/score-table/" + key).then((res) => {
			return res.data;
		});
	},
	generateApiSecret() {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.post(
				"/auth/api-secret",
				{},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	generateScoreTableKey() {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.post(
				"/auth/score-table-key",
				{},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	updateTwitchIntegration(twitchIntegration) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.post(
				"/auth/twitch-integration",
				{
					twitchIntegration,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	updateApiSettings(apiIntegration) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.post(
				"/auth/api",
				{
					apiIntegration,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
	updatePreEnteredRound(roundId, schemeId) {
		const token = checkAuth();
		const auth = "Bearer " + token;
		return api
			.put(
				"auth/update-preentered-round",
				{
					roundId,
					schemeId,
				},
				{
					headers: {
						Authorization: auth,
					},
				}
			)
			.then((res) => {
				return res.data;
			});
	},
};
