import React, { useState, useEffect, useRef, useContext } from "react";
import { Container, Button, Form, Row } from "react-bootstrap";
import ChangeRoomModal from "./ChangeRoomModal/ChangeRoomModal";
import { playerAPI } from "../../api/player-api";
import { useHistory } from "react-router-dom";

import Loader from "./Loader";
import "./roomStyle.scss";
import PlayerHeader from "./PlayerHeader/PlayerHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationModal from "../Admin/Modals/ConfirmationModal";
import { CSSTransition } from "react-transition-group";
import AdSense from "../../components/AdSense/AdSense";
import { GameStoreContext } from "../../store/GameStore";
import { showToast } from "../../util/toast";

const Room = () => {
	const gameStore = useContext(GameStoreContext);
	const [showPlayer, setPlayerModal] = useState(false);
	const [roomExist, setRoomExist] = useState(false);
	const [roomDoesNotExist, setRoomDoesNotExist] = useState(false);
	const [value, setValue] = useState("");
	const [customTitle, setCustomTitle] = useState("");
	const [showCustomLink, setShowCustomLink] = useState(false);
	const [showTriviaMedia, setShowTriviaMedia] = useState(false);
	const [customUrlTitle, setCustomUrlTitle] = useState("");
	const [requirePlayerEmail, setRequirePlayerEmail] = useState(false);
	//const [showRequireEmail, setShowRequireEmail] = useState(false);
	const [customUrl, setCustomUrl] = useState("");
	const [zoomUrl, setZoomUrl] = useState("");
	const [showZoomBtn, setShowZoomBtn] = useState(false);
	const [twitchUrl, setTwitchUrl] = useState("");
	const [showTwitchBtn, setShowTwitchBtn] = useState(false);
	const [limitExceed, setLimitExceed] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [playerData, setPlayerData] = useState({});
	const [inProp, setInProp] = useState(false);
	const [role, setRole] = useState("");
	const [loading, setLoading] = useState(true);
	const [joining, setJoining] = useState(false);
	const input = useRef();
	const room = window.location.pathname.replace("/", "").toUpperCase();

	const history = useHistory();

	const handleChange = (e) => {
		e.persist();
		setValue(e.target.value);
	};
	let name;
	useEffect(() => {
		playerAPI
			.getUserName(room)
			.then((res) => {
				if (res?.name) {
					setValue(res.name);
				}
			})
			.catch((err) => {
				console.log("error", err);
			});

		playerAPI
			.getRoom(room)
			.then((res) => {
				setRoomExist(true);
				setRole(res.role);
				if (res.requirePlayerEmail) {
					setLoading(false);
					const user = JSON.parse(localStorage.getItem("user"));
					if (user?.role !== "user") {
						// sessionStorage.setItem("roomCode", room);
						history.push(`/user/sign-in?room=${room}`);
					}
					setRequirePlayerEmail(true);
				} else {
					setLoading(false);
					console.log("Not requiring email");
				}
				if (res.role === "pro") {
					setCustomTitle(res.customTitle);
					if (res.twitchUrl) {
						setShowTwitchBtn(true);
						setTwitchUrl(res.twitchUrl);
					}
					if (res.zoomUrl) {
						setShowZoomBtn(true);
						setZoomUrl(res.zoomUrl);
					}
					if (res.customUrl) {
						setShowCustomLink(true);
						setCustomUrl(res.customUrl);
						setCustomUrlTitle(res.customUrlTitle);
					}
				} else {
					setLoading(false);
					setShowCustomLink(true);
					setCustomUrl("https://triviarat.com");
					setCustomUrlTitle("Powered By " + window.APP_NAME);
					setShowTriviaMedia(true);
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 404) {
					console.log("error", err.response);
					setRoomDoesNotExist(true);
					setLoading(false);
					setShowCustomLink(true);
					setCustomUrl("https://triviarat.com");
					setCustomUrlTitle("Powered By TriviaRat");
					setShowTriviaMedia(true);
					setCustomTitle("TriviaRat");
				} else {
					alert(err);
				}
			});
	}, [room]);

	let existingTwitch = showTwitchBtn ? "zoom-btn mr-2" : "zoom-btn";

	const handlePlayerModal = () => {
		setPlayerModal(true);
	};

	const handleClose = () => {
		setPlayerModal(false);
	};

	const closeConfirmationModal = () => {
		setValue("");
		input.current && input.current.focus();
		setShowConfirmationModal(false);
	};

	const confirm = () => {
		localStorage.setItem(
			"user",
			JSON.stringify({
				role: "player",
				auth: playerData.accessToken,
				room: room,
				ID: playerData.playerId,
			})
		);
		window.location.pathname = "/player";
		//navigate("/player");
	};

	const play = async (e) => {
		e.preventDefault();
		setJoining(true);

		let trimValue = value.trim();
		// Only set username if there's a user and email registration is required
		if (
			requirePlayerEmail &&
			JSON.parse(localStorage.getItem("user"))?.role === "user"
		) {
			await playerAPI.setNewUserName(room, trimValue);
		}
		name = trimValue;

		localStorage.setItem("room_utm_source", room);

		if (name.length) {
			try {
				const res = await playerAPI.playerLogin(room, name.toUpperCase());
				const notAuthenticated =
					JSON.parse(localStorage.getItem("user"))?.role === "user";

				if (!notAuthenticated && res.status === 200) {
					setShowConfirmationModal(true);
					setPlayerData(res.data);
					setJoining(false);
					return;
				}
				localStorage.setItem(
					"user",
					JSON.stringify({
						role: "player",
						auth: res.data.accessToken,
						room: room,
						ID: res.data.playerId,
					})
				);
				window.location.pathname = "/player";
			} catch (err) {
				setJoining(false);
				if (err.response && err.response.status === 403) {
					setLimitExceed(true);
					setRoomExist(false);
				} else {
					let msg = err.response?.data?.message ?? "Failed to join room";
					showToast.error(msg);
				}
			}
		} else {
			setJoining(false);
			setInProp(true);
			setTimeout(() => setInProp(false), 400);
		}
	};

	if (loading)
		return (
			<>
				<Loader />
			</>
		);

	return (
		<>
			<div className="room">
				<PlayerHeader roomCode={room} />
				<div className="enter-room">
					{roomExist ? (
						<div className="content-wrapper">
							<div className="text-center">
								<h1 className="room-title">{customTitle}</h1>
							</div>
							<div className="main-content">
								{requirePlayerEmail ? (
									<h4 className="content-title pt-3">
										You need to login to join this room.
									</h4>
								) : null}
								<h4 className="content-title py-3">ENTER NAME</h4>
								<Form onSubmit={play}>
									<CSSTransition in={inProp} classNames="name" timeout={200}>
										<Form.Control
											onChange={handleChange}
											size="lg"
											type="text"
											id="join-input"
											autoFocus={true}
											value={value}
											ref={input}
										/>
									</CSSTransition>
								</Form>
								<div className="d-flex justify-content-center pb-3">
									<Button
										onClick={play}
										variant="primary"
										className="tiger"
										id="join-button"
										size="lg"
										disabled={!value || value.length === 0 || joining}
									>
										{joining ? "Joining..." : "Play!"}
									</Button>
								</div>
							</div>
						</div>
					) : null}

					{roomDoesNotExist ? (
						<Container>
							<div className="room-doesnt-exist pb-5">
								<div className="p-4">
									<h2>Ouch...Room code '{room}' doesn't exist..</h2>

									<div className="d-flex justify-content-center">
										<Button variant="primary my-4" onClick={handlePlayerModal}>
											Try a different code
										</Button>
									</div>
									<div className="mt-5">
										Would you like to <a href="/">HOST</a> trivia? Feel free to
										use this code before someone else does :).
									</div>
								</div>
							</div>
						</Container>
					) : null}

					{limitExceed ? (
						<Container>
							<div className="room-doesnt-exist">
								<div className="p-4">
									<h2>No more available space for players in '{room}' room.</h2>
									<p>Please contact your HOST or try again later</p>
								</div>
							</div>
						</Container>
					) : null}
					<>
						<Row
							className={`media-buttons ${
								!!role && role !== "pro" && "with-ad"
							}`}
						>
							{showCustomLink && !window.APP_IS_WHITE_LABEL ? (
								<div className="d-flex justify-content-center  col-12 mb-2">
									<a href={customUrl} target="_blank" rel="noopener noreferrer">
										<Button variant="secondary">
											{showTriviaMedia ? (
												<FontAwesomeIcon icon="bolt" className="mr-2" />
											) : null}
											{customUrlTitle}
										</Button>
									</a>
								</div>
							) : null}
							<div className="d-flex justify-content-center col-12 mb-2">
								{showTriviaMedia && !window.APP_IS_WHITE_LABEL ? (
									<>
										<a
											rel="noopener noreferrer"
											href="https://www.facebook.com/triviarat"
											target="_blank"
										>
											<div className="mr-4 trivia-media">
												<FontAwesomeIcon icon={["fab", "facebook-square"]} />
											</div>
										</a>
										<a
											rel="noopener noreferrer"
											href="https://www.instagram.com/triviarat/"
											target="_blank"
										>
											<div className="trivia-media">
												<FontAwesomeIcon icon={["fab", "instagram"]} />
											</div>
										</a>
									</>
								) : null}
								{showZoomBtn ? (
									<a rel="noopener noreferrer" href={zoomUrl} target="_blank">
										<div className={existingTwitch}>
											<FontAwesomeIcon icon="video" />
										</div>
									</a>
								) : null}
								{showTwitchBtn ? (
									<a
										rel="noopener noreferrer"
										href={twitchUrl}
										className="twitch-btn"
										target="_blank"
									>
										<FontAwesomeIcon
											icon={["fab", "twitch"]}
											className="mr-1"
										/>
									</a>
								) : null}
							</div>
						</Row>
					</>
				</div>
			</div>

			<ChangeRoomModal show={showPlayer} handleClose={handleClose} />
			<ConfirmationModal
				show={showConfirmationModal}
				handleClose={closeConfirmationModal}
				confirm={confirm}
				submitBtnText={`Join ${value.toUpperCase()}`}
				cancelBtnText="Choose a different name"
			>
				<h4 className="text-center">"{value.toUpperCase()}" already exists!</h4>
			</ConfirmationModal>

			{!!role && role !== "pro" && <AdSense />}
		</>
	);
};

export default Room;
