import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	// faHome,
	// faBriefcase,
	// faPaperPlane,
	// faQuestion,
	// faImage,
	// faCopy,
	faTimes,
} from "@fortawesome/free-solid-svg-icons";
import SubMenu from "./SubMenu";
import { Nav, Button } from "react-bootstrap";
import classNames from "classnames";

class SideBar extends React.Component {
	render() {
		const currentUrl = window.location.href;
		const isWhiteLabel =
			currentUrl.includes("play.") || currentUrl.includes("live.");

		return (
			<div className={classNames("sidebar", { "is-open": this.props.isOpen })}>
				<div className="close-button-sidebar">
					<Button
						variant="link"
						onClick={this.props.toggle}
						style={{ color: "#fff" }}
						className="mt-4"
					>
						<FontAwesomeIcon icon={faTimes} pull="right" size="xs" />
					</Button>
				</div>
				<Nav className="flex-column pt-2">
					<Nav.Item className="active">
						<Nav.Link href="/host/contact/support">
							<h5>Contact Support</h5>
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="active">
						<Nav.Link href="/host/pre-entered">
							<h5>Questions</h5>
						</Nav.Link>
					</Nav.Item>

					<Nav.Item className="active">
						<Nav.Link href="/host/settings">
							<h5>Settings</h5>
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="active">
						<Nav.Link href="/host/guide">
							<h5>Guide</h5>
						</Nav.Link>
						{/* <Nav.Link
							onClick={() => {
								this.props.toggle();
								this.props.handleLearnMoreModal();
							}}
						>
							<h5>Guide</h5>
						</Nav.Link> */}
					</Nav.Item>
					<SubMenu
						title="Options"
						items={[
							{ to: "/host/images", title: "Add Images" },
							//{ to: "/host/pre-entered", title: "Pre-Entered Questions" },
							{
								onClick: this.props.handleTrophyCaseModal,
								title: "Trophy Case",
							},
							{
								onClick: this.props.handleChangeRoomCode,
								title: "Change Room Code",
							},
							{ to: "/host/raw-data", title: "View Raw Data" },
							{ to: "/host/round-snapshot", title: "View Round Snapshot" },
							{ to: "/host/users", title: "Password Resets" },
						]}
					/>
					<Nav.Item>
						<Nav.Link href="/host/qr-code">
							<h5>My QR Code</h5>
						</Nav.Link>
					</Nav.Item>

					<Nav.Item className="active">
						<Nav.Link href="/host/premium">
							<h5>Pro Upgrade</h5>
						</Nav.Link>
					</Nav.Item>
					{isWhiteLabel && (
						<Nav.Item>
							<Nav.Link href="/host/users">
								<h5>Password Resets</h5>
							</Nav.Link>
						</Nav.Item>
					)}

					<Nav.Item className="active">
						<Nav.Link onClick={this.props.logOut}>
							<h5>Logout</h5>
						</Nav.Link>
					</Nav.Item>
					{/* <Nav.Item className="active">
						<Nav.Link onClick={() => this.props.setTipsModal(true)}>
							<h4>TriviaRat.com/{this.props.roomCode}</h4>
						</Nav.Link>
					</Nav.Item> */}
				</Nav>
			</div>
		);
	}
}

export default SideBar;
