import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router/Router";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { Slide } from "react-toastify";

if (process.env.REACT_APP_ENV === 'production') {
	Sentry.init({
		dsn: "https://43031b59c3f25b0a3772f9c1cbaa5ae9@o4508892157509632.ingest.us.sentry.io/4508902777618432",
		integrations: [
			new Sentry.BrowserTracing(),
			new Sentry.Replay({
				maskAllText: true,
				blockAllMedia: true,
			}),
		],
		environment: process.env.NODE_ENV,
		tracesSampleRate: 1.0,
		tracePropagationTargets: ["localhost", /^https:\/\/triviarat\.com\/api/],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}
// ..

function App() {
	useEffect(() => {
		// Set CSS variable based on environment
		const backgroundColor = process.env.REACT_APP_BG_COLOR || "#1b3b6f"; // Default fallback
		const backgroundLight = process.env.REACT_APP_BG_LIGHT || "#1c7293"; // Default fallback
		document.documentElement.style.setProperty("--bg-color", backgroundColor);
		document.documentElement.style.setProperty("--bg-light", backgroundLight);
	}, []);

	const url = window.location.href;
	window.APP_BASE_URL = url;
	window.APP_IS_WHITE_LABEL = url.includes("play.") || url.includes("live.");
	window.APP_NAME = !window.APP_IS_WHITE_LABEL ? "TriviaRat" : "Sunset Trivia";
	window.APP_SITE_LABEL = !window.APP_IS_WHITE_LABEL
		? "triviarat.com"
		: "play.sunsettrivia.com";

	return (
		<GoogleOAuthProvider clientId="720549866724-1csgnhshgbh0n79tkajh76n2kdiuadda.apps.googleusercontent.com">
			<BrowserRouter>
				<Router />
				<ToastContainer
					position="bottom-center"
					autoClose={2000}
					hideProgressBar={true}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					transition={Slide}
				/>
			</BrowserRouter>
		</GoogleOAuthProvider>
	);
}

export default App;
