import React, { useState } from "react";
import { Navbar, Button, Nav, NavDropdown } from "react-bootstrap";

import "../../../components/FontAwesomeIcons/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SideBar from "./Sidebar/Sidebar";
import "./style.scss";
import HallOfFameModal from "../Modals/HallOfFameModal/HallOfFameModal";
import ChangeRoomCode from "../Modals/ChangeRoomCode";
import TipModal from "../Modals/TipModal";
import LearnMoreModal from "../Modals/LearnMoreModal";
import { Link, NavLink } from "react-router-dom";
import { faBars, faPlug } from "@fortawesome/free-solid-svg-icons";
const Header = ({ logOut, roomCode, ...props }) => {
	const [showHallOfFame, setShowHallOfFame] = useState(false);
	const [showChangeRoomCode, setShowChangeRoomCode] = useState(false);
	const [showTipsModal, setTipsModal] = useState(false);
	const [showLearnMoreModal, setLearnMoreModal] = useState(false);
	const [expanded, setExpanded] = useState(false);

	const handleTrophyCaseModal = () => setShowHallOfFame(true);

	const handleLearnMoreModal = () => setLearnMoreModal(true);

	const handleClose = () => {
		setShowHallOfFame(false);
		setShowChangeRoomCode(false);
		setTipsModal(false);
		setLearnMoreModal(false);
	};

	const handleChangeRoomCode = () => setShowChangeRoomCode(true);

	const [open, setOpen] = React.useState(true);
	const toggle = () => setOpen(!open);

	return (
		<>
			<div className="App wrapper">
				{expanded ? (
					<SideBar
						handleLearnMoreModal={handleLearnMoreModal}
						toggle={() => setExpanded(!expanded)}
						isOpen={expanded}
						logOut={logOut}
						roomCode={roomCode}
						setTipsModal={setTipsModal}
						handleTrophyCaseModal={handleTrophyCaseModal}
						handleChangeRoomCode={handleChangeRoomCode}
					/>
				) : (
					""
				)}
				<Navbar
					onToggle={() => setExpanded(!expanded)}
					expanded={expanded}
					bg="dark"
					expand="lg"
					className="header"
					style={{ display: "flex", justifyContent: "space-between" }}
					variant="dark"
				>
					<Navbar.Brand>
						<NavLink to="/host/dashboard">
							<Button variant="outline-light" id="dashboard-button">
								DASHBOARD{" "}
							</Button>
						</NavLink>

						{/* <NavLink to="/host/venue-connect">
							<Button variant="link" className="opacity-50">
								VENUE{" "}
								<FontAwesomeIcon
									className="pt-1"
									icon={faPlug}
									transform="shrink-2"
								/>
							</Button>
						</NavLink> */}
					</Navbar.Brand>
					<Button
						variant="link"
						onClick={() => setExpanded(!expanded)}
						style={{ color: "#fff" }}
					>
						<FontAwesomeIcon icon={faBars} pull="right" size="xs" />
					</Button>
				</Navbar>

				<ChangeRoomCode show={showChangeRoomCode} handleClose={handleClose} />
				<div>{props.children}</div>

				<TipModal show={showTipsModal} handleClose={handleClose}>
					<div>
						Your players can play along at triviarat.com with room code{" "}
						{roomCode}, or simply <b>triviarat.com/{roomCode}</b>
						<br />
						<br />
						Or you may present them with your{" "}
						<Link to="/host/qr-code" onClick={handleClose}>
							QR Code
						</Link>
						<br />
						<br />
						<Button
							className="mx-auto d-flex"
							variant="secondary"
							onClick={handleChangeRoomCode}
						>
							Change Code
						</Button>
					</div>
				</TipModal>

				<LearnMoreModal show={showLearnMoreModal} handleClose={handleClose} />

				<HallOfFameModal
					show={showHallOfFame}
					handleClose={handleClose}
					setShowHallOfFame={setShowHallOfFame}
				/>
			</div>
		</>
	);
};

export default Header;
