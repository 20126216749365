import { createContext } from "react";
import { makeAutoObservable } from "mobx";

class RoleStore {
	role = "";
	room = "";

	constructor() {
		makeAutoObservable(this);
	}
}

export const RoleStoreContext = createContext(new RoleStore());
